import React from "react";
import { Button } from "react-bootstrap";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const Leads = ({ dashboardData }) => {
  const navigate=useNavigate();
  return (
    <>
    <div className="card-header p-3 pb-0">
              <h6 className="mb-0">Leads</h6>
              <div className="d-flex">
                <div className="p text-sm font-weight-bold mb-0 ">{dashboardData.leadCount}</div>
              </div>
              <hr className="horizontal dark mb-0" />
            </div>
      <div className="d-flex flex-column mt-3">
        <div className="d-flex justify-content-between align-items-center py-2">
          <h4 className="text-success">
            72 <span className="fs-6 text-dark">Verified</span>
          </h4>
          <span className="text-primary">View</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center py-2">
          <h4 className="text-danger">
            30 <span className="fs-6 text-dark">Failed</span>
          </h4>
          <span className="text-primary">View</span>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button className="border-0 bg-gradient-warning p-2"  onClick={() => navigate('/account/campaigns')}>
          <HiOutlineEye />  View All
        </Button>
      </div>
    </>
  );
};

export default Leads;
