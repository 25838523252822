import React, { useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../../redux/dashboardSlice"; // Adjust path as needed
import Loading from "../../../assets/img/dashbord/loading.gif";
import Allinvoice from "./Allinvoice";

// Function to format the value (e.g., for credits)
const formatValue = (value) => {
  if (value < 1000) {
    return value.toString(); // Keep it as is for numbers below 1000
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // Format to 'K'
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Format to 'M'
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B"; // Format to 'B'
  }
};

const Billing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardData, paymentData, isLoading, error } = useSelector(
    (state) => state.dashboard
  );

  // Fetch dashboard data when the component is mounted
  useEffect(() => {
    if (!dashboardData?.user?.planName) {
      dispatch(fetchDashboardData());
    }
  }, [dispatch, dashboardData?.user?.planName]); // Ensure this only runs when necessary

  // Format the date for display
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Formats as DD/MM/YYYY
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="Loading" width={25} />
        </div>
      ) : error ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <p className="text-danger">{error}</p>
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col lg={12} className="px-0">
              <Col className="p-5">
                <Row>
                  <h4 className="fw-bold mb-4">Billing</h4>
                  <Col
                    lg={6}
                    md={10}
                    sm={6}
                    className="bg-orange1 rounded-4 my-4 p-4"
                  >
                    <Row className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                      {/* Left Section */}
                      <Col
                        md={8}
                        sm={12}
                        className="text-start mb-4 mb-md-0"
                      >
                        <div className="my-1 display-8 ActivePlan">
                          Active Plan
                        </div>
                        <h3 className="mt-lg-0 mt-4 text-white ">
                          {dashboardData?.user?.planName} Plan
                        </h3>
                        <span>
                          Valid till -{" "}
                          {getFormattedDate(dashboardData?.user?.planExpiresOn)}
                        </span>

                        <div className="py-3 d-flex flex-wrap gap-2">
                          <Button
                            className="cta-custom-primary"
                            onClick={() => navigate("/account/addcredits")}
                          >
                            Top-Up Now
                          </Button>

                          <Button
                            className="cta-custom-seccondary"
                            onClick={() => navigate("/account/billing/plans")}
                          >
                            Upgrade Plan
                          </Button>
                        </div>
                      </Col>

                      {/* Right Section */}
                      <Col
                        md={4}
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div className="credits-circle">
                          <div className="credits-content">
                            <h5 className="credits-number text-white ">
                              {formatValue(dashboardData?.credits)}
                            </h5>
                            <span className="credits-label">
                              Credits Available
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <div className="my-5">
                    <h5 className="fw-semibold fs-4" id="invoicehistory">
                      Invoice History
                    </h5>
                    {/* Ensure paymentData is correctly passed as a prop */}
                    <Allinvoice paymentData={paymentData} />
                  </div>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Billing;
