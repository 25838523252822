import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { MdOutlineMailOutline } from "react-icons/md";

const Settings = () => {
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);

  const handleToggle = (type, isEnabled) => {
    console.log(`${type} notifications ${isEnabled ? "enabled" : "disabled"}`);
  };

  return (
    <div className="p-3">
      {/* Email Notifications Setting */}
      <div className="d-flex align-items-center justify-content-between border rounded p-3 mb-3">
        <div className="d-flex align-items-center">
          <div className="icon-circle me-3">
            <span className="icon-bg">
              <MdOutlineMailOutline size={"30px"} color="#6841E0" />
            </span>
          </div>
          <div>
            <h6 className="mb-0">Email</h6>
            <small className="text-muted">Allow email notifications.</small>
          </div>
        </div>
        <Form.Check
          type="switch"
          id="email-notifications"
          checked={emailNotifications}
          onChange={(e) => {
            setEmailNotifications(e.target.checked);
            handleToggle("email", e.target.checked);
          }}
        />
      </div>

      {/* Push Notifications Setting */}
      <div className="d-flex align-items-center justify-content-between border rounded p-3">
        <div className="d-flex align-items-center">
          <div className="icon-circle me-3">
            <i
              className="bi bi-pin"
              style={{ color: "#f76a28", fontSize: "1.5rem" }}
            ></i>
          </div>
          <div>
            <h6 className="mb-0">Push notification</h6>
            <small className="text-muted">Allow push notifications.</small>
          </div>
        </div>
        <Form.Check
          type="switch"
          id="push-notifications"
          checked={pushNotifications}
          onChange={(e) => {
            setPushNotifications(e.target.checked);
            handleToggle("push", e.target.checked);
          }}
        />
      </div>
    </div>
  );
};

export default Settings;
