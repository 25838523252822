import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse"; // CSV parsing library
import { ToastContainer, toast } from "react-toastify"; // For notifications
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import { RxCross1 } from "react-icons/rx";
import csvfileicon from "../../../assets/img/svg/csvfileicon.svg";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import Swal from "sweetalert2";

const Uploadcsv = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [rowCount, setRowCount] = useState(null); // Tracks row count
  const [isUploading, setIsUploading] = useState(false);
  const [isCSV, setIsCSV] = useState(false); // Flag to track if the file is CSV
  const navigate = useNavigate();
  const onclose = useNavigate()

  const { _id } = useParams(); // Campaign ID
  const bearerToken = getCookie("bearerToken");
  const headers = bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {};

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // console.log(`File selected: ${file}`);

    if (file) {
      setFileName(file.name);
      setSelectedFile(file);

      // Check if the file is a CSV
      if (file.type === "text/csv" || file.name.endsWith(".csv")  ) {
        setIsCSV(true);

        // Parse the CSV file to count the rows
        Papa.parse(file, {
          complete: (result) => {
            const rows = result.data.length - 1; // Assuming the first row is the header
            setRowCount(rows);
          },
          header: true,
          error: (error) => {
            console.error("Error parsing file:", error);
            setRowCount(null); // Reset row count in case of error
          },
        });
      } else {
        setIsCSV(false);
        setRowCount(null);
      }
    }
  };

  const handleFileUpload = async () => {
    if (!bearerToken) {
      alert("Authentication failed! Please log in again.");
      return;
    }

    if (selectedFile && isCSV) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("campaignId", _id);

      setIsUploading(true);
      try {
        const response = await axios.post(
          `${baseURL}campaign/importAnyFile`,
          formData,
          { headers }
        );

        const campaignId = response.data.campaignId || _id;
console.log(response);
        // Parse CSV data here (you can get result from Papa.parse)
        Papa.parse(selectedFile, {
          complete: (result) => {
            navigate(`/account/showheaders/${campaignId}`, {
              state: { rowCount, csvData: result.data }, // Pass csvData to the next component
            });
          },
          header: true,
        });
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message;
        Swal.fire({
          title: errorMessage,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f76a28",
          cancelButtonColor: "#000",
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
          backdrop: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if(errorMessage == "Leads already uploaded"){
              navigate(`/account/uploadcsv/${_id}`);
            }
            else
            {
              navigate("/account/addcredits");
            }
          }
        });
      } finally {
        setIsUploading(false);
      }
    } else {
      alert("Please select a valid CSV file first!");
    }
  };


  const handleclose = () => {
    navigate(`/account/createcampaign/`);
  }

  return (
    <div className="csv d-flex justify-content-center align-items-center bg-light">
      <div className="card shadow-sm p-4" style={{ width: "450px" }}>
        {/* Header Section */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="mb-0 fw-bold">
            <span className="me-2">1/3</span> Upload a CSV
          </h6>
          <button className="border-0 bg-transparent" onClick={handleclose}>
            <RxCross1 />
          </button>
        </div>

        {/* Upload Section */}
        <div className="text-center py-3 border rounded bg-light mb-4">
          <img src={csvfileicon} alt="CSV File Icon" className="mb-3" />
          <p className="mb-0">
            <label
              htmlFor="fileUpload"
              className="text-orange text-decoration-underline"
              style={{ cursor: "pointer" }}
            >
              Click to upload
            </label>
            <input
              id="fileUpload"
              type="file"
              accept=""
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            &nbsp;or drag and drop.
          </p>
          <small className="text-muted">Only CSV files are accepted.</small>
        </div>

        {/* File Information Section */}
        {fileName && (
          <div className="p-3 border rounded mb-4 bg-white">
            <div className="d-flex align-items-center">
              <div className="text-success me-3">
                <i className="bi bi-check-circle-fill"></i>
              </div>
              <div>
                <p className="mb-1 fw-bold">{fileName}</p>
                <small className="text-muted">
                  {isCSV
                    ? rowCount !== null
                      ? `${rowCount} leads found`
                      : "Loading..."
                    : "Please upload a CSV file"}
                </small>
              </div>
            </div>
          </div>
        )}

        {/* Action Button */}
        <div className="text-end">
          <button
            className="btn bg-gradient-warning px-4"
            onClick={handleFileUpload}
            disabled={!isCSV || isUploading}
          >
            {isUploading ? "Uploading..." : "Continue"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Uploadcsv;
