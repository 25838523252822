import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../utils/auth";
import { baseURL } from "../utils/constant";

export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (source = "", { getState, rejectWithValue }) => {
    const state = getState();
    const { dashboardData } = state.dashboard;

    // Skip API call if the data is already available, unless it's "fromlogin" or "reload"
    if (
      source !== "fromlogin" &&
      source !== "reload" &&
      dashboardData?.campaignCount > 0
    ) {
      return dashboardData; // Return existing data to avoid unnecessary API call
    }

    const bearerToken = getCookie("bearerToken");
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
    };

    try {
      const response = await axios.get(`${baseURL}dashboard/getMergedData`, {
        headers,
      });
      return response.data?.data || []; // Return the response data or an empty array
    } catch (error) {
      return rejectWithValue(error.message); // Reject if there's an error
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: true,
    dashboardData: {
      campaignCount: 0,
      leadCount: 0,
      credits: 0,
      user: {
        firstName: "",
        lastName: "",
        planName: "",
        planExpiresOn: "",
      },
      pieChart: { labels: [], series: [] },
    },
    campaignData: [],
    leadData: [],
    paymentData: [],
    latestCampaigns: [],
    usageData: [],
    error: null,
  },
  reducers: {
    resetDashboard: (state) => {
      state.isLoading = true;
      state.dashboardData = {
        campaignCount: 0,
        leadCount: 0,
        credits: 0,
        user: {
          firstName: "",
          lastName: "",
          planName: "",
          planExpiresOn: "",
        },
        pieChart: { labels: [], series: [] },
      };
      state.campaignData = [];
      state.leadData = [];
      state.paymentData = [];
      state.latestCampaigns = [];
      state.usageData = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        const data = Array.isArray(action.payload) ? action.payload : [];

        // Extract data for specific categories
        const paymentData = data.find((item) => item.source === "payment");
        const paymentDataArray = paymentData
        ? Object.values(paymentData).filter((value) => typeof value === "object")
        : [];
        const campaignData = data.find((item) => item.source === "campaign");
        const userData = data.find((item) => item.source === "user");
        const pieChartData = data.find((item) => item.source === "pieChart");
        const usageData =
          data.find((item) => item.source === "usage")?.data?.graphData || [];

        // Update state with the fetched data
        state.dashboardData = {
          campaignCount: campaignData?.data?.length || 0,
          leadCount: campaignData?.data?.reduce(
            (sum, campaign) => sum + campaign.leads || 0,
            0
          ),
          credits: userData?.credits || 0,
          user: {
            firstName: userData?.firstName || "",
            lastName: userData?.lastName || "",
            planName: userData?.planName || "",
            planExpiresOn: userData?.planExpiresOn || "",
          },
          pieChart: {
            labels: pieChartData?.labels || [],
            series: pieChartData?.series || [],
          },
        };
        state.paymentData = paymentDataArray;
        state.latestCampaigns = campaignData?.data.slice(0, 2) || [];
        state.campaignData = campaignData?.data || [];
        state.usageData = usageData;
        state.isLoading = false;
        
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
