import React from "react";
import { Container } from "react-bootstrap";
import { CiUser } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";
import { ImHeadphones } from "react-icons/im";
import { IoMdSearch } from "react-icons/io";
import dottedline from "../../assets/img/svg/arrow/RightArrow.svg";
import verticalarrow from "../../assets/img/svg/arrow/VerticalArrow.svg";


const VerificationChart = () => {
  return (
    <div className="verify-sec-cnt mt-5">
      <Container fluid className="verify-cnt-1">
        <div className="verify-sec d-flex">
          <div className="email-verify d-flex border">
            <div class="d-flex email-row">
              <div className="verify-icon-col d-flex align-items-center">
                <div className="email-verify-icon">
                  <HiOutlineMailOpen
                    size={"24px"}
                    color={"rgba(104, 65, 224, 1)"}
                  />
                </div>
                <div>
                  <span className="email-verify-text">Email verify</span>
                </div>
              </div>
              <div className="verify-text-col d-flex justify-content-end align-items-center">
                <FaCheckCircle className="email-icon" />
              </div>
              <div className="email-hover-text">
                <p>
                  Look up a person by their email address.Look up a person by
                  their email address.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="dotted-line1"></div> */}
          <div className="d-flex justify-content-center dotted-home-line">
            <img src={dottedline} alt="" className="img-fluid dotted-home-line-svg" />
          </div>
          <div className="person-verify d-flex border">
            <div class="d-flex person-row">
              <div className="verify-icon-col d-flex align-items-center">
                <div className="person-verify-icon">
                  <CiUser size={"24px"} color={"rgba(0, 147, 76, 1)"} />
                </div>
                <div>
                  <span className="person-verify-text">Person Check</span>
                </div>
              </div>
              <div className="verify-text-col d-flex justify-content-end align-items-center">
                <FaCheckCircle className="person-icon" />
              </div>
              <div className="person-hover-text">
                <p>
                  Enrichment lets you look up person and company data based on
                  an email or domain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="d-flex justify-content-center dotted-line-vertical">
          <img src={verticalarrow} alt="" className="img-fluid" />
        </div>
      <Container fluid className="verify-cnt-2 mb-5 pb-5">
        <div className="verify-sec d-flex mt-5">
          <div className="voice-verify d-flex border">
            <div class="d-flex voice-row">
              <div className="verify-icon-col d-flex align-items-center">
                <div className="voice-verify-icon">
                  <ImHeadphones size={"24px"} color={"#5293FF"} />
                </div>
                <div style={{ width: "200px" }}>
                  <span className="voice-verify-text">Voice Call Check</span>
                </div>
              </div>
              <div className="verify-text-col d-flex justify-content-end align-items-center">
                <FaCheckCircle className="voice-icon" />
              </div>
              <div className="voice-hover-text">
                <p>
                  Enrichment lets you look up person and company data based on
                  an email or domain.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center  dotted-home-line">
            <img src={dottedline} alt="" className="img-fluid dotted-home-line-svg" />
          </div>
          <div className="company-verify d-flex border">
            <div class="d-flex company-row">
              <div className="verify-icon-col d-flex align-items-center">
                <div className="company-verify-icon">
                  <IoMdSearch size={"24px"} color={"#B7AF04"} />
                </div>
                <div style={{ width: "275px" }}>
                  <span className="company-verify-text">
                    Company Verification
                  </span>
                </div>
              </div>
              <div className="verify-text-col d-flex justify-content-end align-items-center">
                <FaCheckCircle className="company-icon" />
              </div>
              <div className="company-hover-text">
                <p>
                  Enrichment lets you look up person and company data based on
                  an email or domain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VerificationChart;
