import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
// import { fetchCampaigns } from "../../../redux/campaignSlice";
import Swal from "sweetalert2";
import { Col, Container, Row, Button, Dropdown, Form } from "react-bootstrap";
// import { GoPlus } from "react-icons/go";
import DataTable from "react-data-table-component";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { GoSearch, GoPlus } from "react-icons/go";
import { LuRefreshCcw } from "react-icons/lu";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import { baseURL } from "../../../utils/constant";
import axios from "axios";

const PastCampaignRedux = () => {
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(5); // Default entries per page
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };

  const { campaignData: campaignData, isLoading } = useSelector(
    (state) => state.dashboard
  );


  // console.log(state)

  const filteredData = useMemo(() => {
    if (!campaignData) return [];
    let filtered = campaignData;

    // Filter by search text (Campaign Name only)
    if (searchText.trim()) {
      const searchTerm = searchText.toLowerCase();
      filtered = filtered.filter((row) =>
        row.campaignName.toLowerCase().includes(searchTerm)
      );
    }

    // Filter by status
    if (statusFilter !== "All") {
      filtered = filtered.filter((row) => row.status === statusFilter);
    }

    // Filter by date
    if (dateFilter) {
      // Ensure the date format is comparable
      const filterDate = new Date(dateFilter).toLocaleDateString();
      filtered = filtered.filter(
        (row) => new Date(row.createdAt).toLocaleDateString() === filterDate
      );
    }

    return filtered;
  }, [campaignData, searchText, statusFilter, dateFilter]);

  const handleRefresh = () => {
    dispatch(fetchDashboardData("reload")); // Refresh campaign data
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this campaign?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
      }
    });
  };

  const handleEdit = (id, campaignName) => {
    // Show SweetAlert2 dialog
    Swal.fire({
      title: `Do you want to rename <b>${campaignName.toUpperCase()}</b>?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      input: "text",
      inputLabel: "New Campaign Name",
      inputPlaceholder: "Enter the new name",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter a campaign name!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        const newCampaignName = result.value;

        try {
          headers.campaignid = id;
          // Hit the API with header and body
          const response = await axios.post(
            `${baseURL}campaign/updateCampaign`, // API endpoint
            {
              campaignName: newCampaignName, // Body
            },
            { headers }
          );

          // Show success notification
          Swal.fire({
            title: "Success!",
            text: `Campaign renamed to "${newCampaignName}" successfully.`,
            icon: "success",
            confirmButtonColor: "#f76a28",
          });
          handleRefresh();
        } catch (error) {
          // Show error notification
          Swal.fire({
            title: "Error!",
            text: "Failed to rename the campaign. Please try again later.",
            icon: "error",
            confirmButtonColor: "#f76a28",
          });
          console.error("Error renaming campaign:", error);
        }
      }
    });
  };

  // Dropdown Action Component

  // Custom Toggle for Dropdown
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ display: "inline-block" }}
    >
      {children}
    </div>
  ));

  // Column definitions for DataTable
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => (row && (index + 1).toString().padStart(2, "0")) || "-",
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
        row && row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB")
          : "-",
      sortable: true,
    },
    {
      name: "Campaign Name",
      selector: (row) =>
        row && row.campaignName
          ? row.campaignName.charAt(0).toUpperCase() + row.campaignName.slice(1)
          : "No Campaign Data Found",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span className="status-badge">
          <span
            className={`badge1 ${row && row.status === "New"
                ? "badge badge-info"
                : row && row.status === "Pending"
                  ? "badge badge-warning"
                  : row && row.status === "Complete"
                    ? "badge badge-success"
                    : ""
              }`}
          >
            {row && row.status === "Pending" ? "In Progress" : row?.status || "-"}
          </span>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <BiDotsHorizontalRounded size={24} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            <Dropdown.Item

              onClick={() => {
                if (row.status === "New") {
                  navigate(`/account/uploadcsv/${row._id}`);
                }
                else if (row.status === "Pending") {

                  navigate(`/account/stepprogressbar/${row._id}`);
                }
                else {

                  navigate(`/account/completeStatus/${row._id}`);
                }
              }}
            >
              <MdOutlineRemoveRedEye className="me-2" /> View
            </Dropdown.Item>

            <Dropdown.Item
              className="text-orange"
              onClick={() => handleEdit(row._id, row.campaignName)} // Updated Edit action
            >
              <AiOutlineEdit className="me-2" /> Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              onClick={() => handleDelete(row._id)}
            >
              <AiOutlineDelete className="me-2" /> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];


  return (
    <div className="container-fluid my-4">
      {isLoading ? (
        <div className="text-center">
          <img src={Loading} alt="Loading" width={25} />
        </div>
      ) : (
        <>


          <div className="card">
            {/* Card Header */}
            <div className="card-header pb-0">
              <div className="d-lg-flex">
                <div>
                  <h5 className="mb-0">Campaigns</h5>
                  <p className="text-sm mb-0">
                    All campaign's entries
                  </p>
                </div>
                <div className="ms-auto my-auto mt-lg-0 mt-4">
                  <div className="ms-auto my-auto">

                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn bg-gradient-primary btn-sm mb-0"
                        onClick={() => navigate("/account/createcampaign")}
                      >
                        +&nbsp; New Campaign
                      </Button>
                      <span className="m-2" onClick={handleRefresh}>
                        <LuRefreshCcw size={20} className="me-1 sq-db-icon" />
                      </span>
                    </div>


                    {/* Import Modal */}
                    <div className="modal fade" id="import" tabIndex="-1" aria-hidden="true">
                      <div className="modal-dialog mt-lg-10">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="ModalLabel">Import CSV</h5>
                            <i className="fas fa-upload ms-3"></i>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <p>You can browse your computer for a file.</p>
                            <input
                              type="text"
                              placeholder="Browse file..."
                              className="form-control mb-3"
                            />
                            <div className="form-check is-filled">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="importCheck"
                                defaultChecked
                              />
                              <label className="custom-control-label" htmlFor="importCheck">
                                I accept the terms and conditions
                              </label>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn bg-gradient-secondary btn-sm"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" className="btn bg-gradient-primary btn-sm">
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* Card Body */}
            <div className="card-body px-0 pb-0">
              <div className="table-responsive">
                <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                  <div className="table-responsive">
                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                      <div className="dataTable-top">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="dataTable-search d-flex align-items-center">
                            <div className="me-4">
                              <Dropdown>
                                <Dropdown.Toggle variant="" id="status-filter-dropdown" className="ms-2"> {/* Add ms-2 or use custom margin/padding */}
                                  {statusFilter === "All" ? "Search Status" : statusFilter}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => setStatusFilter("All")}>All</Dropdown.Item>
                                  <Dropdown.Item onClick={() => setStatusFilter("New")}>New</Dropdown.Item>
                                  <Dropdown.Item onClick={() => setStatusFilter("Pending")}>In Progress</Dropdown.Item>
                                  <Dropdown.Item onClick={() => setStatusFilter("Complete")}>Complete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            <input
                              className="dataTable-input"
                              placeholder="Search Campaigns..."
                              type="text"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          </div>

                          {/* Status Dropdown */}

                        </div>
                      </div>

                      {/* DataTable Section */}
                      <div className="table-container" style={{ position: 'relative' }}>
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          noHeader={false}
                          persistTableHead={true}
                          className={"table align-items-center mb-0"}
                          noDataComponent={<div>No Campaign Found</div>}
                          customStyles={{
                            headCells: {
                              style: {
                                position: "sticky",
                                top: 0,
                                fontWeight: "bold",
                                fontSize: "14px",
                                zIndex: 10,
                                backgroundColor: "#EBE7FC",
                              },
                            },
                            footer: {
                              style: {
                                position: "sticky",
                                bottom: 0,
                                fontWeight: "bold",
                                fontSize: "14px",
                                zIndex: 10,
                                backgroundColor: "#EBE7FC",
                              },
                            },
                          }}
                        />
                      </div>

                      <div className="dataTable-bottom">
                        <div className="dataTable-info">
                          Showing {filteredData.length} entries
                        </div>
                        <nav className="dataTable-pagination">
                          <ul className="dataTable-pagination-list"></ul>
                        </nav>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </div>

  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default PastCampaignRedux;
