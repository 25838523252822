import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import Navbar from "../../components/Navbar.jsx";
import Accordion from "react-bootstrap/Accordion";
import { HiComputerDesktop } from "react-icons/hi2";

function FaqPage() {
  return (
    <>
  
      <Container className="py-5">
        <Row>
          <Col lg={9} className="mx-auto">
            <div className="mb-5">
              <Link to="/" className="text-decoration-none text-black">
                Back to Home Page
              </Link>
            </div>
            <h1 className="mb-5 text-center">Frequently Asked Questions</h1>
            <Row>
              <Col>
                <div className="mb-5 text-center">
                  <h4>Need some help?</h4>
                  <h6>Choose category to see related answers</h6>
                </div>
                <Row>
                  <Col lg={4}>
                    <Link to="/faq/general-information" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">General Information</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/getting-started" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">Getting Started</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/campaign-management" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">Campaign Management</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/account-management" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">Account Management</p>
                    </Col>
                  </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/plans-and-billing" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Plans and Billing</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/security-and-privacy" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Security and Privacy</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/troubleshooting" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Troubleshooting</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/help-and-support" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Help and Support</p>
                    </Col>
                    </Link>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default FaqPage;


