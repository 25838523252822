import React from 'react'
import { Button, Container } from 'react-bootstrap'

const BeforeFooterCTA = () => {
  return (
    <div>
        <Container fluid className="py-5 d-flex justify-content-center bg-light">
        {/* <div className="bgsection6"> */}
        <div className="card shadow-lg bgsection6">
          <h2 className="fw-bold mb-4 freetrial">
            Start your <br />
            15 days <span className="free-text">Free</span> trial
          </h2>
          <div className="d-flex justify-content-start gap-3 mb-4">
            <Button className="talk-to-your-team-btn">Talk to our team</Button>
            <Button className="btn-outline-light claim-btn">
              Claim 100 Credits
            </Button>
          </div>
          <div className="mt-3 ms-auto">
            <Button className="me-3  cancel-home-btn">Cancel anytime. </Button>
            <Button className="feature-home-btn">All features included.</Button>
          </div>
        </div>
        {/* </div> */}
      </Container>
    </div>
  )
}

export default BeforeFooterCTA
