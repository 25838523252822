import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdCopyright } from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../assets/img/svg/logo/bluelogo.svg";
import { Facebook } from "react-bootstrap-icons";
import {
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { CiTwitter } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer mt-auto py-5 bg-light smartqc-footer">
      <Container fluid className="px-5 smartqc-footer-cnt">
        <Row className="align-items-center justify-content-between smartqc-footer-row pt-4">
          {/* Footer Logo */}
          <Col
            xs={12}
            md={3}
            className="text-center text-md-start mb-3 mb-md-0"
          >
            <img
              src={logo}
              alt="Footer Logo"
              className="smartqc-footernav-logo img-fluid"
            />
          </Col>
          {/* Footer Text */}
          <Col xs={12} md={8} className="">
            <div className="footer-social-icons">
              <ul className="list-unstyled d-flex flex-wrap justify-content-center gap-3 mb-0">
                <li>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark bg-light p-2 rounded-5"
                  >
                    <FaFacebookF size={"25px"} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark bg-light p-2 rounded-5"
                  >
                    <FaXTwitter size={"25px"} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark p-2 rounded-5 bg-light"
                  >
                    <FaInstagram size={"25px"} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark p-2 rounded-5 bg-light"
                  >
                    <FaLinkedin size={"25px"} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="pt-3 footer-nav">
              <ul className="list-unstyled d-flex flex-wrap footer-nav-list gap-3 mb-0">
                <li>
                  <Link
                    to={"/terms-of-service"}
                    className="text-decoration-none text-dark fs-6 "
                  >
                    Terms of Services
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/support"}
                    className="text-decoration-none text-dark fs-6"
                  >
                    Support
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/privacy-policy"}
                    className="text-decoration-none text-dark fs-6"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cancellation"}
                    className="text-decoration-none text-dark fs-6"
                  >
                    Cancellation
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/faq"}
                    className="text-decoration-none text-dark fs-6"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <p className="text-muted  f-text text-end pt-2 ">
              &copy; smartqc. Missing the cookie banner? Go ahead and browse, we
              don’t track you.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
