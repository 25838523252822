import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import banner from "../../assets/img/home/bluehomeherobg.png";
import rightarrow from "../../assets/img/svg/rightarrow.svg";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <>
      <div className="bgsection1 ">
        <Container fluid className="d-flex flex-column ">
          {/* Header Section */}
          <Row className="flex-grow-1 d-flex align-items-center justify-content-center text-center py-4">
            <Col xs={12} md={10} lg={9}>
              <h1 className="hero-title ">
                Enhance your leads with the <br /> future of data quality
              </h1>
              <p className="hero-desc mt-5">
                SmartQC is your buddy for top-notch data quality!
              </p>
              <Link to="/register">
                <button className="btn mt-5">
                  {" "}
                  <span className="">Get Started</span>{" "}
                  <span>
                    {" "}
                    {/* <img src={rightarrow} alt="" /> */}
                    <FiArrowRight />
                  </span>
                </button>
              </Link>
            </Col>
          </Row>

          {/* Image Section */}
          <Row className="flex-grow-1 d-flex align-items-center justify-content-center">
            <Col xs={12} md={10} lg={8}>
              <div className="">
                <img
                  src={banner}
                  alt="SmartQC Banner"
                  className="img-fluid rounded"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;
