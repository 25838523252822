import { useNavigate, useLocation } from "react-router-dom";
import { isLogin } from "../utils/auth";
import { useEffect } from "react";
import { toast } from "react-toastify";

const TokenValidator = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current route

  useEffect(() => {
    const validateToken = async () => {
      try {
        const isValid = await isLogin(navigate);

        // If the token is invalid or missing
        if (!isValid) {
          if (location.pathname !== "/login") {
            // Show toast only when not on the login page
            // toast.error("Token is invalid or expired, please log in again.", {
            //   position: "bottom-left",
            //   autoClose: 5000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            // });
            // navigate("/login");
          }
        }
      } catch (error) {
        console.error("Error during token validation:", error);
        toast.error("An error occurred while validating your session. Please try again.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    };

    validateToken(); // Initial token validation

    // Revalidate token every minute
    const intervalId = setInterval(validateToken, 60000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [navigate, location.pathname]);

  return null; // This component doesn't render anything
};

export default TokenValidator;
