import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdCheckmark } from "react-icons/io";

const Verification = () => {
  return (
    <div>
      <Container fluid className="pt-5 bgsection2">
        <div className="validation-title">
          <h2 className="fw-bold mb-4 fs-2">
            Boost Sales with CRM <br />
            Integration and Verified Leads
          </h2>
        </div>
        <Row className="g-4 validation-row">
          <Col md={4}>
            <div className=" h-100 d-flex flex-column justify-content-start align-items-start pt-4">
              <h5 className="fw-bold mb-2 ">
                {" "}
                <span className="me-1">
                  <IoMdCheckmark />
                </span>
                Lead Validation
              </h5>
              <p className="ms-4 lead-validation-content">
                We verify name, job title,
                <br /> phone number, email,
                <br /> company, and industry details.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className=" h-100 d-flex flex-column justify-content-start align-items-start pt-4">
              <h5 className="fw-bold mb-2">
                {" "}
                <span className="me-1">
                  <IoMdCheckmark />
                </span>{" "}
                Sales Pipeline
              </h5>
              <p className="sales-pipeline-content ms-4">
                We improve efficiency with
                <br /> accurate and verified lead data.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className=" h-100 d-flex flex-column justify-content-start align-items-start pt-4">
              <h5 className="fw-bold mb-2">
                {" "}
                <span className="me-1">
                  <IoMdCheckmark />
                </span>{" "}
                Data Input Options
              </h5>
              <p className="data-input-content ms-4">
                Receive data directly or pull it
                <br /> from your CRM or MAP.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Verification;
