import { Container, Row, Col, Spinner, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
// import SubNav from "../../../Components/subNav";
import { useNavigate, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NewSidebar2 from "../../../components/NewSidebar2";
import Table from "react-bootstrap/Table";
import { FaCheck } from "react-icons/fa6";
import Badge from "react-bootstrap/Badge";
import Loading from "../../../assets/img/dashbord/loading.gif";

const Billing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const bearerToken = getCookie("bearerToken");

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = () => {
      axios
        .get(`${baseURL}plan/getPlanList`, {
          headers: { ...headers },
        })
        .then((response) => {
          const Response = response.data;
          const plansWithRegion = Response.data.map((plan, index) => {
            let region;
            switch (index) {
              case 0:
                region = "North America Only";
                break;
              case 1:
                region = "North America and EMEA";
                break;
              case 2:
                region = "All";
                break;
              case 3:
                region = "All";
                break;
              default:
                region = "Unknown";
            }
            return { ...plan, region: region, planId: index + 1 };
          });
          setPlans(plansWithRegion);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
        });
    };
    fetchData();
  }, []);

  const makePayment = async (_id, amount, planName, planDetails) => {
    try {
      // unit_amount, currency, product_data, credits, userId, planId
      // Prepare payload for Stripe API call
      const payload = {
        planId: _id,
        unit_amount: amount * 100, // Convert to cents
        currency: "usd",
        product_data: {
          name: planName,
        }
      };
  
      // Call the Stripe API
      const response = await fetch(`${baseURL}payment/stripe-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json();
  
      if (response.ok && responseData.url) {
        // Redirect to Stripe Checkout
        window.location = responseData.url;
      } else {
        // Handle errors if no redirect URL is available
        alert("An error occurred while processing your request.");
      }
    } catch (error) {
      // Log and alert in case of an exception
      alert("An error occurred while processing your payment request.");
    }
  };
  

  const convertDaysToMonths = (days) => {
    const months = Math.floor(days / 30); // Divide days by 30 to get months
    return months >= 1
      ? `${months} ${months > 1 ? "months" : "month"} validity`
      : `Contact Sales`;
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              {/* <NewSidebar2 /> */}
              <Col lg={12} className="px-0">
                {/* <SubNav/> */}
                <Col className="p-4">
                  <div className="pb-3">
                    <Link
                      to="/account/billing"
                      className="text-decoration-none text-black"
                    >
                      Back to Billing
                    </Link>
                  </div>
                  <h4 className="fw-bold pb-3">Plans</h4>
                  <div className="d-flex align-items-center">
                    <Container fluid>
                      <Row>
                        {plans.map((plan) => (
                          <Col lg={3} key={plan._id} className="my-auto">
                            <Col
                              lg={12}
                              className={`border rounded-4  ${
                                plan.planName === "Phantom"
                                  ? "card-hover1 py-5 px-4"
                                  : "p-4 "
                              } `}
                            >
                              <h5 className={`${
                                plan.planName === "Phantom"
                                  ? "text-light"
                                  : ""
                              } `}>
                                {plan.planName}
                                {plan.planName === "Phantom" && (
                                  <span>
                                    <Badge
                                      className={`ms-1 preferred-badge ${
                                        plan.planName === "Phantom"
                                          ? "text-light"
                                          : ""
                                      } `}
                                      style={{
                                        backgroundColor: "#fff",
                                        color: "#ed7b1e",
                                      }}
                                    >
                                      Preferred
                                    </Badge>
                                  </span>
                                )}
                              </h5>

                              <h3 
                              className={`fw-bold ${
                                plan.planName === "Phantom"
                                  ? "text-light"
                                  : ""
                              } `}>
                                {plan.planPrice === 0
                                  ? "Contact Sales"
                                  : `$ ${plan.planPrice}`}
                              </h3>

                              <div>
                                {plan.planCredits === 0
                                  ? "Contact Sales"
                                  : plan.planCredits}{" "}
                                {plan.planCredits === 0 ? "" : "credits"}
                              </div>
                              <div>
                                {convertDaysToMonths(plan.planDuration)}
                              </div>
                              <div className="mb-3"> {plan.region}</div>

                              <div className="d-grid">
                                <Link
                                  className={`btn-sm btn px-4 ${
                                    plan.planName === "Phantom"
                                      ? "Phantom-button-hover"
                                      : "bg-gradient-warning"
                                  }`}
                                  onClick={() =>
                                    plan.planPrice === 0
                                      ? (window.location.href = "/contact")
                                      : makePayment(
                                          plan._id,
                                          plan.planPrice,
                                          plan.planName,
                                          plan.planDetails
                                        )
                                  }
                                  type="button"
                                  disabled={plan.planPrice === 0}
                                >
                                  {plan.planPrice === 0
                                    ? "Contact Sales"
                                    : "Buy Now"}
                                </Link>
                              </div>

                              <div className="mt-2">
                                <div>
                                  <FaCheck /> Voice Validation
                                </div>

                                <div>
                                  <FaCheck /> LinkedIn Validation
                                </div>

                                <div>
                                  <FaCheck /> Email Validation
                                </div>

                                <div>
                                  <FaCheck /> Dupe Check
                                </div>

                                <div>
                                  <FaCheck /> Real Time Report Access
                                </div>

                                <div>
                                  <FaCheck /> QA Assurance
                                </div>
                              </div>
                            </Col>
                          </Col>
                        ))}
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
export default Billing;