import React, { useState } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/dashbord/newlogo.png";
import { GoPlus } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { CiBullhorn } from "react-icons/ci";
import { PiInvoiceLight } from "react-icons/pi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import StateManagedSelect from "react-select";

function getFormattedDate(dateString) {
  const date = new Date(dateString);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${date.getDate()} ${month}, ${year}`;
}
const credits = 0;
const user = {
  name: "John Doe", 
};
function NewSidebar2() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {/* Sidebar for large screens */}
      <Col
        lg={2}
        className="py-4 px-4 bg-white vh-100 sticky-top d-none d-lg-block sb-shadow-right"
      >
        <div className="mb-4 text-start">
          <Link>
            <img src={logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div className="d-grid gap-2 mb-4">
          <Button
            variant="btn bg-gradient-warning py-2 p-0"
            onClick={() => navigate("/account/createcampaign")}
          >
            <span className="me-1">
              <GoPlus size={"30px"} />
            </span>
            New Campaign
          </Button>
        </div>
        <Nav defaultActiveKey="/home" className="flex-column vh-80 d-flex">
          <Link
            className="text-decoration-none fs-6 text-dark mb-3  p-2 dashboard"
            to="/account/dashboard"
          >
            <span className="me-2">
              <RxDashboard />
            </span>
            Dashboard
          </Link>
          <Link
            className="text-decoration-none fs-6 text-dark mb-3  p-2 dashboard"
            to="/account/campaigns"
          >
            <span className="me-2">
              <CiBullhorn />
            </span>
            Campaigns
          </Link>
          <Link
            className="text-decoration-none fs-6 text-dark mb-3  p-2 dashboard"
            to="/account/billing"
          >
            <span className="me-2">
            <LiaFileInvoiceSolid />
            </span>
            Billing
          </Link>
          <div className="mt-auto sidebar-card">
            <Row>
              <Col lg={12} className="sq-sidbar-bg-grey rounded-4 my-4 p-2">
                <Row>
                  <Col className="text-start">
                    <div className="my-1 display-8 ActivePlan">Active Plan</div>
                    <h5 className="fw-bold fs-6">
                      {user.planName
                        ? `${user.planName} Plan`
                        : "No Active Plan"}
                    </h5>
                    <span>
                      {user.planExpiresOn
                        ? `Valid till - ${getFormattedDate(user.planExpiresOn)}`
                        : "No active subscription"}
                    </span>
                    <div className="py-3">
                      <button
                        className=" btn bg-upgradePlan w-100"
                        onClick={() => navigate("/account/billing/plans")}
                      >
                        Upgrade
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Nav>
      </Col>

      {/* Toggleable sidebar for smaller screens */}
      <div className="d-lg-none">
        <Button
          variant="btn bg-gradient-warning"
          className="mb-2 border-0"
          onClick={() => setSidebarOpen(!sidebarOpen)} // Corrected
        ></Button>
        {sidebarOpen && ( // Corrected
          <div className="bg-white vh-100 position-fixed top-0 start-0 px-4 py-4">
            <div className="mb-4 text-center">
              <a
                className="text-decoration-none text-dark fw-lighter fs-2"
                href="#"
              >
                smart<span className="text-orange fw-bold">qc</span>
              </a>
            </div>
            <div className="d-grid gap-2 mb-4">
              <Button
                variant="btn bg-gradient-warning"
                onClick={() => {
                  navigate("/account/createcampaign");
                  setSidebarOpen(false); // Corrected
                }}
                className="w-100"
              >
                New Campaign
              </Button>
            </div>
            <Nav defaultActiveKey="/home" className="flex-column">
              <Link
                className="text-decoration-none fs-6 text-dark mb-3"
                to="/account/dashboard"
                onClick={() => setSidebarOpen(false)} // Corrected
              >
                <span>
                  <RxDashboard />
                </span>
                Dashboard
              </Link>
              <Link
                className="text-decoration-none fs-6 text-dark mb-3"
                to="/account/campaigns"
                onClick={() => setSidebarOpen(false)} // Corrected
              >
                Campaigns
              </Link>
              <Link
                className="text-decoration-none fs-6 text-dark mb-3"
                to="/account/billing"
                onClick={() => setSidebarOpen(false)} // Corrected
              >
                Billing
              </Link>
              <Row>
                <Col lg={12} className="bg-orange1 rounded-4 my-4 p-1">
                  <Row>
                    <Col className="text-start">
                      <div className="my-1 display-8 ActivePlan">
                        Active Plan
                      </div>
                      <h5 className="fw-bold fs-3">
                        {user.planName || "No Plan"}
                      </h5>
                      <span>
                        {user.planExpiresOn
                          ? `Valid till - ${getFormattedDate(
                              user.planExpiresOn
                            )}`
                          : "No active subscription"}
                      </span>
                      <div className="credits mt-3">
                        <h6>Credits: {credits || 0}</h6>
                      </div>
                      <div className="py-3">
                        <button
                          className="m-2 btn bg-upgradePlan"
                          onClick={() => navigate("/account/billing/plans")}
                        >
                          Refill Now
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Nav>
          </div>
        )}
      </div>
    </>
  );
}

export default NewSidebar2;
