import React, { useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import locklottie from '../../assets/lottie/lock-lottie.json'
import { useLottie, useLottieInteractivity } from "lottie-react";

const LockAnimation = () => {
  const options = {
    animationData: locklottie,
    loop: true,
    autoplay: false, // Set to false to control animation manually
    speed: 0.5 // Speed of animation
  };

  const containerRef = useRef(null);
  const lottieObj = useLottie(options);
  
  // Set up scroll-based interactivity
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll", 
    actions: [
      {
        visibility: [0, 1],
        type: "seek",
        frames: [0, 200], // Controls the frame range you want to show during scroll

        
      },
    ],
  });

  return (
    <>
      <Container fluid className="col-lg-12 bg-security-section mt-5 " ref={containerRef}>
        <div className="py-5">
          <h2 className="security-sec-title mb-4 px-2 text-center">Privacy</h2>
          <div className="row lottie-row">
            <div className="col-lg-2">
              {/* The Lottie animation */}
              <div style={{ width: "100%", height: "auto" }}>
                {lottieObj?.View}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="security-sec-para">
              <p className="security-para">
                We’re built on trust. Every lead you upload stays safe,
                encrypted, and fully compliant with GDPR, CCPA, and other
                leading data regulations.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LockAnimation;
