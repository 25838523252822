import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Navbar from "../../components/Navbar.jsx";
import Footer from "../../components/Footer.jsx";
import axios from 'axios'; // Ensure axios is imported
import { useNavigate } from 'react-router-dom';
import { getCookie } from "../../utils/auth.jsx";
import { baseURL } from "../../utils/constant.jsx";
import { toast } from "react-toastify";


function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); 
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      name: name,
      email: email,
      message: message,
    };

    axios.post(`${baseURL}user/submitContactForm`, payload, {
      headers: { ...headers },
    })
      .then((response) => {
        setIsLoading(false);
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.reload();
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (err.response && err.response.status === 401) {
          navigate("/");
        }
      });
  };

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={7} className="my-auto">
            <h1 className="mb-5 text-start">Ready to validate your contacts?</h1>
            <p>Or reach out to us - contact@smartqc.io</p>
          </Col>
          <Col lg={5}>
            <Col lg={12} className="p-5 border rounded-3">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label className="fw-bold">Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="fw-bold">Business Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label className="fw-bold">What's on your mind?</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className="text-center">
                  <Button
                    type="submit"
                    className="btn bg-gradient-warning"
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Form>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
