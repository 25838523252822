import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default to localStorage for web
import dashboardReducer from "./dashboardSlice"; 
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

// Configuration for redux-persist
const persistConfig = {
  key: "dashboard", // Key under which the state will be persisted
  storage,          // Storage engine (localStorage)
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, dashboardReducer);

// Configure the store with the persisted reducer and customized middleware
const store = configureStore({
  reducer: {
    dashboard: persistedReducer, // Use the persisted reducer for the dashboard slice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the persistor to sync the store with storage
export const persistor = persistStore(store);

export default store;
