import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Navigation from "../components/Navigation";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Scrollbar from "smooth-scrollbar"; // Correct import

const PublicLayout = () => {
  const scrollRef = useRef(null);
  const location = useLocation(); // Get current route information

  useEffect(() => {
    // const options = {
    //   damping: 0.1, // Smoothness of the scrolling
    // };

    // if (scrollRef.current) {
    //   // Initialize smooth scrollbar on the container
    //   const scrollbarInstance = Scrollbar.init(scrollRef.current, options);

    //   return () => {
    //     scrollbarInstance.destroy(); // Cleanup on unmount
    //   };
    // }
  }, []);

  // Check if the current path is login or register
  const isAuthPage = location.pathname === "/login" || location.pathname === "/register";

  return (
    <div ref={scrollRef} style={{ height: "100vh", overflow: "visible" }}>
      <div>
        {!isAuthPage && <Navigation />}
        <div className="container-fluid p-0 public-layout">
          <Outlet />
        </div>
        {/* Conditionally render Footer */}
        {!isAuthPage && <Footer />}
      </div>
    </div>
  );
};

export default PublicLayout;
