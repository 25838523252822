import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

function EnrichLeadsData() {
  const { state } = useLocation(); // Get state from navigation
  const { rowCount, campaignId, csvData } = state || {}; // Extract rowCount, campaignId, and csvData
  const navigate = useNavigate(); // useNavigate hook

  const handleNext = () => {
    // Pass csvData to the next component
    navigate(`/account/importLeads/${campaignId}`, {
      state: { rowCount, campaignId, csvData },
    });
  };

  const handlePrev = () => {
  

    navigate(`/account/showheaders/${campaignId}`, campaignId,{
      state: { rowCount, campaignId, csvData },
    });
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex justify-content-center align-items-center"
    >
      <Container className="h-75 w-75 p-4 border border-2 rounded bg-white">
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="fw-bold">3/3</div>
              <div className="fs-5">Enrich leads data</div>
              <button className="border-0 bg-transparent">
                <RxCross1 size={24} />
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="headersSection border border-2 rounded p-3">
              <div className="d-flex justify-content-between py-1">
                <p>
                  <Form>
                    {["checkbox"].map((type) => (
                      <div
                        key={`default-${type}`}
                        className="mb-3 custom-checkbox"
                      >
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`verify your leads ${type}`}
                        />
                      </div>
                    ))}
                  </Form>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-end mt-5">
              <button className="previous mx-2" onClick={handlePrev}>
                Previous
              </button>
              <button className="btn bg-gradient-warning" onClick={handleNext}>
                Import {rowCount > 0 ? `${rowCount} ` : "No leads found"} Leads
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EnrichLeadsData;
