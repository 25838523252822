import { Row, Col, Form, Button, Container } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useParams here
import { useParams } from "react-router-dom";
// import logo from "../../assets/img/home/logo.png";
import axios from "axios";
import { baseURL } from "../../utils/constant";
import { toast } from "react-toastify";
import logo from "../../assets/img/login&sign-up/logo.png";
import { CiLock } from "react-icons/ci";
import { FaEye, FaEyeSlash } from "react-icons/fa";


function ResetPassword() {
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [bearerToken, setBearerToken] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== password1) {
      toast("Passwords do not match!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
    } else {
      toast("Passwords matched. Submitting form...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });

      const payload = {
        password,
        password1,
      };
      try {
        axios
          .post(`${baseURL}auth/changePasswordByLink`, payload, {
            headers,
          })
          .then((res) => {
            if (res.data.status === 200) {
              toast("Password Change Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // transition: Bounce,
              });
              setBearerToken(res.data.newToken);
              navigate("/login");
            } else {
              toast("Failed to generate new token!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // transition: Bounce,
              });
            }
          })
          .catch((err) => {
            toast("Password Change Failed!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              // transition: Bounce,
            });
          });
      } catch (error) {
        toast("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      }
    }
  };

  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="d-flex align-items-center bg-login bg-orange-gradient">
      <Container fluid>
        <Row>
          <Col lg={4} className="vh-100 d-flex align-items-center mx-auto">
            <Col lg={10} className="p-0">
              <div className="card border-0 shadow bg-white rounded-5">
                <div className="card-body p-5">
                  <div className="py-3">
                    <Container className="d-flex justify-content-center align-items-center">
                      <Link to="/">
                        <img src={logo} alt="Logo" />
                      </Link>
                    </Container>
                  </div>
                  <h4 className="fw-bold  mb-4+">Reset password</h4>

                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4 position-relative">
                      <Form.Label className="label-password-class px-2">
                        New password
                      </Form.Label>
                      <div className="input-group password-group">
                        <span className="input-group-text bg-white border-end-0">
                          <CiLock className="text-muted" />
                        </span>
                        <Form.Control
                          type={passwordVisible ? "text" : "password"}
                          className="rounded-end form-control-sm py-3 border-start-0 input-orange w-100"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent password-icon"
                          onClick={handlePasswordToggle}
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative">
                      <Form.Label className="label-password-class px-2">
                      Confirm new password
                      </Form.Label>
                      <div className="input-group password-group">
                        <span className="input-group-text bg-white border-end-0">
                          <CiLock className="text-muted" />
                        </span>
                        <Form.Control
                          type={passwordVisible ? "text" : "password"}
                          className="rounded-end form-control-sm py-3 border-start-0 input-orange w-100"
                          name="password"
                          value={password1}
                          onChange={(e) => setPassword1(e.target.value)}
                        />
                        <button
                          type="button"
                          className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent password-icon"
                          onClick={handlePasswordToggle}
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                    </Form.Group>

                    <div className="d-grid gap-2 mb-4">
                      <Button
                        type="submit"
                        className="btn bg-gradient-warning rounded-3 py-3 border-0"
                        disabled={isLoading} // Disable button when loading
                      >
                        {isLoading ? "Resetting in..." : "Continue"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default ResetPassword;
