import React from 'react'
import Navbar from "../../../components/Navbar.jsx";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "../../../components/Footer.jsx";

function CampaignManagement() {
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">Campaign Management</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How do I create a campaign?
                </Accordion.Header>
                <Accordion.Body>
                  You can create your campaign by clicking on New Campaign. Add your campaign name and select fields to be qualified for the campaign and click on continue. Your campaign is created!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How do I import data to the campaign
                </Accordion.Header>
                <Accordion.Body>
                    To import data to the campaign, follow these steps -
                    <ul>
                        <li>
                            Access the Import function - Navigate to the campaign in the SmartQC interface where file import option is available. This is usually found in the campaign page.
                        </li>
                        <li>
                            Choosing File Type - SmartQC only supports CSV file format.
                        </li>
                        <li>
                            Upload the file - Use the file upload functionality to browse your computer and select the file you wish to import. Or simply drag and drop the file in the upload section.
                        </li>
                    </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How can I check the status of the campaign?
                </Accordion.Header>
                <Accordion.Body>
                    To check the status of a campaign, you can utilize the campaign monitoring feature available in the system's dashboard. Here is the general outline of the steps involved - 
                    <ul>
                        <li>Login to SmartQC - Login to your account</li>
                        <li>Navigate to the Dashboard - The dashboard is the central hub where you can monitor various aspects of your data quality processes.</li>
                        <li>Select the Campaign - From the dashboard, locate your campaign</li>
                        <li>View Campaign Status - Once you have selected the specific campaign you are interested in, you should see a detailed view that includes the status of the campaign. This may include progress indicators, completion rates, and any rates related to the campaign</li>
                        <li>Detailed Metrics and Reports - For more detailed information, you can access specific metrics and reports.</li>
                    </ul>
                </Accordion.Body>
              </Accordion.Item>
              
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CampaignManagement