import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/constant";
import React, { useState, useEffect } from "react";
import logo from "../../assets/img/login&sign-up/logo.png";
import { IoMailOutline } from "react-icons/io5";
import { getCookie, isLogin, setAuthentication } from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function Login({ setisAuthenticated }) {
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin(navigate)) {
        navigate("/");
      }
    };
    authenticate();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
    setIsButtonDisabled(!email || !password);
  };

  const handleSubmit = async (e) => {
    // const token = localStorage.getItem("token");
    e.preventDefault();
    try {
      const payload = { email };
      const response = await axios.post(
        `${baseURL}auth/resetPassword`,
        payload,
        {
          headers: { ...headers },
        }
      );
      const { message } = response.data;
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate(`/login`);
     
    } catch (error) {
      toast(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="d-flex align-items-center bg-login bg-orange-gradient">
      <Container fluid>
        <Row>
          <Col lg={4} className="vh-100 d-flex align-items-center mx-auto">
            <Col lg={10} className="p-0">
              <div className="card border-0 shadow bg-white rounded-5">
                <div className="card-body p-5">
                  <div className="py-3">
                    <Container className="d-flex justify-content-center align-items-center">
                      <Link to="/">
                        <img src={logo} alt="Logo" />
                      </Link>
                    </Container>
                  </div>
                  <h4 className="fw-bold  mb-4 ">Forgot password</h4>

                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4">
                      <Form.Label className="label-email-class px-2">
                        Email
                      </Form.Label>
                      <div className="input-group">
                        <span className="input-group-text bg-white border-end-0">
                          <IoMailOutline className="text-muted" />
                        </span>
                        <Form.Control
                          type="email"
                          className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>

                    <div className="d-grid gap-2 mb-4">
                      <Button
                        type="submit"
                        className="btn bg-gradient-warning  rounded-3 py-3 border-0"
                        disabled={isLoading}
                      >
                        {isLoading ? "Linking in..." : " Send password reset link"}
                      </Button>
                    </div>
                  </Form>

                  <div className="text-center">
                   
                    <Link
                      to="/login"
                      className="text-decoration-none text-orange"
                    >
                      Back to Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}