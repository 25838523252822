import React, { useEffect, useRef } from "react";

import Section1 from "../Home/Section1";
import Section2 from "../Home/Section2";
import Section3 from "../Home/Section3";
import Footer from "../../../components/Footer";
import Section4New from "../Home/Section4New";
import Section5New from "../Home/Section5New";
import Section6New from "../Home/Section6New";
import Section9 from "../Home/Section9";
import Section8 from "../Home/Section8";
import Section7 from "../Home/Section7";
import SectionFaq from "../Home/SectionFaq";

import Scrollbar from "smooth-scrollbar";
import Navigation from "../../../components/Navigation";
import Sec1 from "../../../components/landingpage/Sec1";
import BeforeFooterCTA from "../../../components/landingpage/BeforeFooterCTA";
import HeroSection from "../../../components/landingpage/HeroSection";
import Verification from "../../../components/landingpage/Verification";
import VerificationChart from "../../../components/landingpage/VerificationChart";
import LockAnimation from "../../../components/landingpage/LockAnimation";
import FaqSection from "../../../components/landingpage/FaqSection";

// css import
import '../../../landing.css'

function LandingPage() {
  return (
    <>
      <div className="landingpage-cnt">
        <HeroSection />
        <div className="verify-parent-sec bg-white">
          <Verification />
          <VerificationChart />
        </div>
        <LockAnimation />
        <FaqSection />
        {/* <Sec1 /> */}
        <BeforeFooterCTA />
      </div>
    </>
  );
}

export default LandingPage;
