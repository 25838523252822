import React, { useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";

const FaqSection = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const toggleIcon = (key) => (
    <BiPlus
      size="24px"
      color="#263134"
      className={`rotate-icon ${activeKey === key ? "rotate-45" : ""}`}
    />
  );

  return (
    <div className="faq-sec bg-white">
      <Container fluid className="py-3 col-lg-8 col-md-12">
        <h2 className="faq-sec-title mb-4 pt-5">Frequently Asked Questions</h2>
        <Accordion
          activeKey={activeKey}
          onSelect={(key) => handleToggle(key)}
          className="faq-home-accordian py-5"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span className="faq-text">
                  Can you provide more information about SmartQC?
                </span>
                <span>{toggleIcon("0")}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <span className="faq-text-span">
                SmartQC is the comprehensive data quality check tool you need to
                validate, monitor, and maintain the quality of your data.
              </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span className="faq-text">
                  How do I get started with SmartQC?
                </span>
                <span>{toggleIcon("1")}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <span className="faq-text-span">
                Getting started with SmartQC is easy! Sign up for an account on
                our website and follow the instructions to set up your account.
              </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span className="faq-text">Can I request a demo or trial?</span>
                <span>{toggleIcon("2")}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <span className="faq-text-span">
                Yes, we offer demo/trials to prospective customers. This allows
                you to experience our product/service firsthand and see how it
                fits your needs.
              </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span className="faq-text">
                  How do I update my account information?
                </span>
                <span>{toggleIcon("3")}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <span className="faq-text-span">
                You can update your account information by logging into your
                account on our website and navigating to the “Settings” or
                “Profile” section. From there, you can edit and save any changes
                to your account details.
              </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span className="faq-text">
                  What support options are available for SmartQC users?
                </span>
                <span>{toggleIcon("4")}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <span className="faq-text-span">
                SmartQC offers various support options including email support
                and a comprehensive knowledge base. Our dedicated support team
                is available to assist you with any questions or issues you may
                encounter.
              </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span className="faq-text">
                  How do I get started with SmartQC?
                </span>
                <span>{toggleIcon("5")}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <span className="faq-text-span">
                Getting started with SmartQC is easy! Simply sign up for an
                account on our website, and you’ll receive instructions on how
                to set up your account and start using our platform
              </span>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
};

export default FaqSection;
