import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Container, Row, Col, Button, Alert, Form, ProgressBar } from "react-bootstrap";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { toast } from "react-toastify";
import { baseURL } from "../../../utils/constant";
import { fetchDashboardData } from "../../../redux/dashboardSlice";

const StepProgressBarRedux = () => {

  const {_id} = useParams();
  const campaignId = _id;
  // const { campaignId } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
console.log(`Campaign ID: ${campaignId}`);
  // Local State
  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
    leadFields: [],
    isCSV: false,
    emailCheck: false,
    personCheck: false,
    companyCheck: false,
    voiceCallCheck: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMode, setViewMode] = useState("grid");
  const [showImportButton, setShowImportButton] = useState(true);
  const [crossIconVisible, setCrossIconVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  // Redux State
  const campaignData = useSelector((state) => state.dashboard.campaignData); // Fetch campaigns
  const isDashboardLoading = useSelector((state) => state.dashboard.isLoading); // Loading status

  // Fetch dashboard data on component mount
  useEffect(() => {
    if (!campaignData || campaignData.length === 0) {
      dispatch(fetchDashboardData());
    }
  }, [dispatch, campaignData]);

  // Update local state when the campaignData is fetched
  useEffect(() => {
    if (campaignData?.length > 0) {
      const currentCampaign = campaignData.find((campaign) => campaign._id === campaignId);
      if (currentCampaign) {
        setIdData({
          campaignName: currentCampaign.name,
          status: currentCampaign.status,
          recordCount: currentCampaign.recordCount,
          leadFields: currentCampaign.leadFields,
          isCSV: currentCampaign.isCSV,
          emailCheck: currentCampaign.emailCheck,
          personCheck: currentCampaign.personCheck,
          companyCheck: currentCampaign.companyCheck,
          voiceCallCheck: currentCampaign.voiceCallCheck,
        });
        setData(currentCampaign.qualifiedData || []);
      } else {
        toast.error("Campaign not found!", { position: "bottom-right" });
        navigate("/"); // Redirect if the campaign is not found
      }
    }
    setIsLoading(false);
  }, [campaignData, campaignId, navigate]);

  const calculateProgress = (data) => {
    const steps = [
      { name: "Import CSV", completed: data.isCSV },
      { name: "Email Lookup", completed: data.emailCheck },
      { name: "Prospect Verification", completed: data.personCheck },
      { name: "Company Verification", completed: data.companyCheck },
      { name: "Voice Authentication", completed: data.voiceCallCheck },
    ];

    const completedSteps = steps.filter((step) => step.completed);
    const isFinalStep = steps.every((step) => step.completed);

    return isFinalStep
      ? [...steps, { name: "Completed", completed: true, finalStep: true }]
      : steps;
  };

  const steps = calculateProgress(IdData);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowImportButton(false);
      setCrossIconVisible(true);
    }
  };

  const handleFileDeselect = () => {
    setSelectedFile(null);
    setShowImportButton(true);
    setCrossIconVisible(false);
  };

  if (isLoading || isDashboardLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      <Row>
        <Col className="p-4">
          {IdData.status === "New" && (
            <Col lg={12} className="mx-auto text-center">
              <Alert variant="warning">
                <span>
                  <FaExclamation className="text-warning me-2" /> Please check the CSV file format <a href="#">here</a> before importing.
                </span>
              </Alert>
              {showImportButton && (
                <div className="text-center">
                  <Button variant="orange" className="mb-4">
                    Import CSV Document
                  </Button>
                  <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                  <p>or drop a file</p>
                </div>
              )}
              {crossIconVisible && selectedFile && (
                <div>
                  <p className="text-success cursor-pointer">
                    Selected File: {selectedFile.name} <span onClick={handleFileDeselect}>&#10006;</span>
                  </p>
                  <Button variant="orange">Submit</Button>
                </div>
              )}
            </Col>
          )}

          {IdData.status === "Pending" && (
            <Col lg={12} className="mx-auto text-center">
              <p className="fs-5 text-orange">Sit back and relax. Your leads are getting verified.</p>
              <div className="progress-div d-flex justify-content-center">
                <ul className="progress vertical py-5 vh-60">
                  {steps.map((step, index) => (
                    <li
                      key={index}
                      className={
                        step.completed
                          ? step.finalStep
                            ? "inactive final-step"
                            : "active"
                          : "inactive"
                      }
                    >
                      {step.completed ? (
                        <FaCheck className="checkmark" />
                      ) : (
                        <div className="loader-1">
                          <div className="ripple"></div>
                        </div>
                      )}
                      <span>{step.name}</span>
                      <ProgressBar
                        striped
                        variant="warning"
                        now={step.completed ? 100 : 60} // Dynamically changes based on step completion
                        className="stepbar"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          )}

          {IdData.status === "Complete" && (
            <div>
              {showAlert && (
                <Alert variant="warning" dismissible onClose={() => setShowAlert(false)}>
                  <span>Do you want to enrich the data?</span>
                  <Button variant="orange" className="ms-2">
                    Yes
                  </Button>
                </Alert>
              )}
              <Row>
                <Col lg={6} className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Form.Select
                    value={entriesPerPage}
                    onChange={(e) => setEntriesPerPage(e.target.value)}
                  >
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>
                  </Form.Select>
                </Col>

                <Col lg={3} className="d-flex justify-content-end">
                  <div
                    className={`icon1 ${viewMode === "list" ? "active1" : ""}`}
                    onClick={() => setViewMode("list")}
                  >
                    <FaCheck size={20} />
                  </div>
                  <div
                    className={`icon1 ms-3 ${viewMode === "grid" ? "active1" : ""}`}
                    onClick={() => setViewMode("grid")}
                  >
                    <TfiLayoutGrid3 size={20} />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default StepProgressBarRedux;
