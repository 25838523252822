import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import Container from "react-bootstrap/Container";
import { Row, Col, Button } from "react-bootstrap";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { PiCoinsThin, PiUsersThin } from "react-icons/pi";
import { CiBullhorn } from "react-icons/ci";
import UsageCharts from "../../../components/dashboard/charts/UsageCharts";
import CampaignChart from "../../../components/dashboard/charts/CampaignChart";
import RecentCampaigns from "../../../components/dashboard/RecentCampaigns";
import Leads from "../../../components/dashboard/Leads";
import Credits from "../../../components/dashboard/Credits";
import { LuRefreshCcw, LuCoins } from "react-icons/lu";
import DashboardCredits from "../../../components/dashboard/DashboardCredits";

const DashboardRedux = () => {
  const dispatch = useDispatch();
  const { isLoading, dashboardData, latestCampaigns, usageData, error } =
    useSelector((state) => state.dashboard);

  // useEffect(() => {
  //   // Fetch data only if the dashboard data is not available in the Redux store
  //   if (!dashboardData.campaignCount) {
  //     dispatch(fetchDashboardData());
  //   }
  // }, [dispatch, dashboardData]);
  const handleRefresh = () => {
    dispatch(fetchDashboardData("reload")); // Refresh campaign data
  };
  
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <img src={Loading} alt="loading" width={25} />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="sq-dashboard py-3 px-5">
      <Container fluid>
        <Row className="g-4">
          <div className="d-flex justify-content-end px-5">
            <Button
              onClick={handleRefresh}
              className="bg-transparent border-0 d-flex align-items-center"
            >
              <LuRefreshCcw size={20} className="me-1 sq-db-icon" />
            </Button>
          </div>
          <Col xs={12} sm={6} md={4} lg={4}>
            <div className="p-3 card">
            <div className="card-body p-2">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Leads</p>
                        <h5 className="font-weight-bolder">
                        {dashboardData.leadCount}
                        </h5>
                        <p className="mb-0">
                          {/* <span className="text-success text-sm font-weight-bolder">+55%</span>
                          since plan purchased */}
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-primary shadow-primary text-center rounded-circle">
                        <LuCoins style={{ fontSize: '24px', opacity: 0.9 }} aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4}>
            <div className="p-3 card">
            <div className="card-body p-2">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Campaigns</p>
                        <h5 className="font-weight-bolder">
                        {dashboardData.campaignCount}
                        </h5>
                        <p className="mb-0">
                          {/* <span className="text-success text-sm font-weight-bolder">+55%</span>
                          since plan purchased */}
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-primary shadow-primary text-center rounded-circle">
                      <CiBullhorn style={{ fontSize: '24px', opacity: 0.9 }} aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4}>
            <DashboardCredits dashboardData={dashboardData} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="py-4">
        <Row className="gap-2 gap-lg-4 d-flex justify-content-around">
          <Col
            xs={12}
            md={6}
            lg={6}
            className="card theme-border rounded-3 p-3"
          >
            <div className="card-header p-3 pb-0">
              <h6 className="mb-0">Usage</h6>
              <div className="d-flex">
                <div className="p text-sm font-weight-bold mb-0 ">Based on Campaign Activity</div>
                
              </div>
              <hr className="horizontal dark mb-0" />
            </div>
            <UsageCharts data={usageData} />
          </Col>
          <Col
            xs={12}
            md={5}
            lg={5}
            className="card -3 theme-border p-3"
          >
            <div className="card-header p-3 pb-0">
              <h6 className="mb-0">All Campaigns</h6>
              <div className="d-flex">
                <div className="p text-sm font-weight-bold mb-0 ">{dashboardData.campaignCount}</div>
                
              </div>
              <hr className="horizontal dark mb-0" />
            </div>
            <CampaignChart className="mt-8" data={dashboardData.pieChart} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="py-4">
        <Row className="gap-2 gap-lg-4 d-flex justify-content-around">
          <Col
            lg={3}
            md={6}
            sm={12}
            className="p-3 card"
          >
            <Leads dashboardData={dashboardData} />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="p-3 card fit-content"
          >
            <Credits dashboardData={dashboardData} />
          </Col>
          <Col
            lg={5}
            md={12}
            className="p-3 card"
          >
            <RecentCampaigns latestCampaigns={latestCampaigns} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardRedux;
