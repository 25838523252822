import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner, Container, Row, Col } from "react-bootstrap";

const StripeFailed = () => {
  const [counter, setCounter] = useState(5); // Initialize counter to 5 seconds
  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      navigate("/account/billing/plans"); // Redirect to the plans page
    }
  }, [counter, navigate]);

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <Row>
        <Col xs={12} className="text-center">
          <div className="mb-4">
            <Spinner animation="border" variant="primary" />
          </div>
          <h4 className="text-danger">Payment Failed</h4>
          <p className="text-secondary">
            Redirecting to plans in <strong>{counter}</strong> seconds...
          </p>
        </Col>g
      </Row>
    </Container>
  );
};

export default StripeFailed;
